import { useState, useEffect } from "react"
import Cookies, { CookieAttributes } from "js-cookie"

const useJSONCookie = (
  key: string,
  initialValue: any,
  initialOptions?: CookieAttributes
) => {
  // Can we use a cookie if we find it?
  const cookiesAllowed = Cookies.get(`gdpr`) === `false`

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from cookie by key if we are allowed
      const item = cookiesAllowed && Cookies.get(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      return initialValue
    }
  })

  // Account for changes occuring outside this hook
  // If the current value differs from the stored value, update accordingly
  const currentItem = cookiesAllowed && Cookies.get(key),
    currentValue = currentItem ? JSON.parse(currentItem) : undefined

  useEffect(() => {
    if (typeof currentValue === `undefined`) return
    if (currentValue !== storedValue) setStoredValue(currentValue)
  }, [currentValue, storedValue])

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to cookie.
  const setValue = (value: any, options: CookieAttributes) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Proceed to save cookie only if GDPR banner has been accepted
      if (Cookies.get(`gdpr`) === `false` || key === `gdpr`) {
        // Save to cookie
        Cookies.set(
          key,
          JSON.stringify(valueToStore),
          Object.assign({}, initialOptions, options)
        )
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }

  return [storedValue, setValue]
}

const usePlainCookie = (
  key: string,
  initialValue: any,
  initialOptions?: CookieAttributes
) => {
  // Can we use a cookie if we find it?
  const cookiesAllowed = Cookies.get(`gdpr`) === `false`

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    // Get from cookie by key if we are allowed
    const item = cookiesAllowed ? Cookies.get(key) : undefined
    // Return item or initialValue
    return typeof item !== `undefined` ? item : initialValue
  })

  // Account for changes occuring outside this hook
  // If the current value differs from the stored value, update accordingly
  const currentItem = cookiesAllowed ? Cookies.get(key) : undefined,
    currentValue = typeof currentItem !== `undefined` ? currentItem : undefined

  useEffect(() => {
    if (typeof currentValue === `undefined`) return
    if (currentValue !== storedValue) setStoredValue(currentValue)
  }, [currentValue, storedValue])

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to cookie.
  const setValue = (value: any, options: CookieAttributes) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Proceed to save cookie only if GDPR banner has been accepted
      if (Cookies.get(`gdpr`) === `false` || key === `gdpr`) {
        // Save to cookie
        Cookies.set(
          key,
          valueToStore,
          Object.assign({}, initialOptions, options)
        )
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }

  return [storedValue, setValue]
}

export { usePlainCookie, useJSONCookie }